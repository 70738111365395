.wrap {
  background: #1F163F;
  margin-top: 15px;
  padding-bottom: 20px;
  margin-bottom: 25px;

  .blockBonus {
    .blockBonusHeader {
      display: flex;
      padding-top: 20px;
    }

    .img {
      height: 110px;
      margin-right: 12px;
    }
  }

  .titleText {
    margin-top: 10px;
  }

  .routeBonus {
    display: flex;
    justify-content: space-between;


    .routeBonusBlock {
      border-radius: 10px;
      margin-top: 30px;
      background: #531A99;
      padding: 40px 40px;
      width: 48%;
      @media (max-width: 576px) {
        padding: 20px 20px;
        div {
          text-align: center;
        }
      }
    }
  }
}

.labelSmall {
  display: none;
}

@media (max-width: 576px) {
  h2 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  .labelBig {
    display: none;
  }
  .labelSmall {
    display: block;
  }
}


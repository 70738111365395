.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.phw-60 {
  padding: 20px 12px;
}

.mb-30-none {
  margin-bottom: -25px;
}

.mb-30 {
  margin-bottom: 25px;
}

@media screen and (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
  .pb-120 {
    padding-bottom: 80px;
  }
  .pt-80 {
    padding-top: 60px;
  }
  .pb-80 {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 70px;
  }
  .pb-120 {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 575px) {
  .pt-120 {
    padding-top: 60px;
  }
  .pb-120 {
    padding-bottom: 60px;
  }
  .pt-80 {
    padding-top: 60px;
  }
  .pb-80 {
    padding-bottom: 60px;
  }
}

.container {
  max-width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;

    .content {
      margin-top: 64px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 720px;

    .content {
      margin-top: 64px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (max-width: 420px) {
  h1, h2 {
    font-size: 28px;
    line-height: 40.8px;
  }
}
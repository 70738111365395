.breadcrumbsContent {
  background: var(--Breadcumnd);
  padding: 1px 38px 30px;
  font-size: 18px;

  @media (max-width: 576px) {
    padding: 17px 19px 15px;
    font-size: 16px;
  }

  svg {
    margin: 0 15px;
  }
}
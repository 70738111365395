.mySkeleton {
  background: var(--body);
  height: 20px;
  border-radius: 4px;
}

.mySkeleton.wave {
  position: relative;
  overflow: hidden;
}

.mySkeleton.wave::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, #ccc, transparent);
  animation: wave 1.5s infinite;
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.mySkeleton.text {
  width: 100%;
  height: 1em;
}

.menuGameType {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;

  li {
    display: flex;
    align-items: center;
    padding: 9px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--pragraph);
    cursor: pointer;
    position: relative;

    span {
      color: var(--pragraph);
    }

    &:hover {
      background-color: var(--hover-bg);
    }

    img {
      margin-right: 10px;
    }

    &.dropdownItem {
      display: flex;
      align-items: center;
      position: relative;

      .dropdownTitle {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }

      .dropdownArrow {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #C6C6CB transparent transparent transparent;
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          transition: transform 0.3s;
        }

        &.open::after {
          transform: translateY(-50%) rotate(180deg);
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          background: var(--tabe-bg);
          border-radius: 10px;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.3s;
          position: absolute;
          width: 209px;
          top: 19px;
          right: -19px;


          &.open {
            max-height: 200px;
          }

          li {
            background: var(--tabe-bg);
            display: flex;
            align-items: center;
            padding: 9px 20px;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            color: #C6C6CB;
            cursor: pointer;
            width: inherit;

            &:hover {
              background-color: var(--hover-bg);
            }

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.menuGameType li:hover span {
  color: #fff;
}

.menuGameType li:hover span {
  color: var(--paragraph-hover-color);
}

.noHidden {
  overflow: inherit !important;
}

.elementsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 30px 0;

  .element {
    position: relative;
    width: 100%;
    height: 160px;
    background-color: gray;
    overflow: hidden;
    border-radius: 10px;

    @media (min-width: 576px) {
      width: calc(50% - 11px);
    }
  }
}

.elementHover {
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(22, 14, 49, 0) -7.64%, rgba(39, 26, 81, 0.81) 98.03%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: bottom 0.3s ease-in-out;

  .element:hover & {
    bottom: 0;
  }
}

button {
  .element:hover .elementHover & {
    margin-top: 70px;
  }
}

.elementButton {
  padding: 10px 20px;
}


@media (min-width: 768px) {
  .element {
    width: calc(33.33% - 10px);
  }
}

@media (min-width: 992px) {
  .element {
    width: calc(25% - 10px);
  }
}

.gameImage {
  width: 100%;
  height: auto;
}

.gameName {
  text-align: center;
  margin: 10px 0;
}

.LoadMoreStyle {
  padding: 39px;
  margin: auto 0;
  width: 100%;
}
.btn {
  cursor: pointer;
  border-radius: 5px;
  padding: 11px 20px 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: inline-block;
  color: var(--title);
  border: none;
  text-transform: capitalize;
  position: relative;
  min-width: max-content;

  &.primary {
    background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
    transition: all ease 0.5s;

    &:hover {
      background: linear-gradient(180deg, #FF6E04 -1.75%, #FFC876 100.23%);
      color: var(--white4);
    }
  }

  &.secondary {
    background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    overflow: hidden;
    transition: all ease 0.5s;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 5px;
      background: linear-gradient(180deg, #FF6E04 -1.75%, #FFC876 100.23%);
      transition: all ease 0.5s;
    }

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      z-index: -1;
      border-radius: 5px;
      background-color: var(--box-two);
    }

    &:hover {
      &:before {
        background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
      }
    }
  }

  &.withoutStyle {
    color: var(--white);
    background: var(--box-two);
  }

  @media (max-width: 575px) {
    padding: 7px 15px 8px;
    font-size: 12px;
    line-height: 14px;
    width: 77px;
    height: 31px;
  }

  @media (min-width: 1601px) {
    font-size: 18px;
    line-height: 21px;
    width: 87px;
    height: 42px;
  }
}

.disabled {
  cursor: default;
  background: gray !important;
  color: var(--title);
}

.slider-container {
  position: relative;
  margin: auto;
}

@media screen and (max-width: 400px) {
  .slider-container {
    padding: 60px 0;
  }
  .slider-header {
    h2 {
      font-size: 23px;
    }

    .slider-buttons {
      margin-top: 0;
    }
  }
}

.slider-title {
  margin: 0;
  padding: 0;
}

.slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.slider-buttons button {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}

.slide-background {
  display: flex;
  align-items: center;
  padding: 18px 18px;
  background: var(--tabe-bg);
  border-radius: 10px;
}

.slide-photo {
  height: 100%;
  border-radius: 10px;
}

.slide-text {
  flex: 2;
  padding: 0 10px;
  font-size: 22px;
}

.slide-button {
  padding: 5px 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.slick-slide {
  padding-left: 10px;
}

.fa, .fas {
  font-weight: 900;
  color: var(--white);
}

.prev {
  background: var(--prev) !important;
  width: 38px;
  height: 38px;
  transition: all 0.3s;
  border-radius: 50% !important;
  border: none !important;

  &:hover {
    background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%) !important;
  }

  .fa-chevron-left:before {
    content: "\f053";
  }
}

.next {
  background: var(--next) !important;
  width: 38px;
  height: 38px;
  transition: all 0.3s;
  border-radius: 50% !important;
  border: none !important;

  &:hover {
    background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%) !important;

  }

  .fa-chevron-right:before {
    content: "\f054";
  }
}
.menu {
  position: sticky;
  top: 0;
}

.menuPromot {
  background: var(--tabe-bg);
  border-radius: 10px;
  padding: 15px 0;
}

.titleMenu {
  padding: 9px 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #C6C6CB;
}

.menu ul li {
  padding: 9px 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  transition: background-color 0.3s;

  img {
    margin-right: 10px;
  }

  span {
    font-size: 16px;
    line-height: 19.2px;
    color: var(--pragraph);
  }

  &:hover {
    background-color: var(--hover-bg);
    transition: all 0.8s !important;

    span {
      color: var(--white);
    }
  }
}

.menuFilter {
  padding: 15px 0;
}

.menuFilter.open {
  max-height: 300px;
}

.dropdownArrow {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--white);
}

.left-sidebar {
  width: 250px;
  transition: width 0.5s;
  background-color: var(--box-two);
  position: sticky;
  padding: 35px 15px 0;
  z-index: 99;
  flex-shrink: 0;
}

@media screen and (max-width: 1200px) {
  .left-sidebar {
    width: 0;
    transition: width 0.5s;
    overflow: hidden;
    padding: 0;
  }
}

.menuOnTop {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: var(--box-two);
}

.sidebarOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 255, 0.5);
  z-index: 999;
}

.sidebarOverlay.open {
  display: block;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
  height: 18px;
  width: 20px;
}

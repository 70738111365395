.slider-container-group {
  padding: 0 12px;

  .slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .slider-buttons button {
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .slide {
    display: flex;
    justify-content: space-between;
  }

  .slide-row {
    display: flex;
    width: 100%;
  }

  .slide-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 7px;
    width: 100%;
  }

  .slide-item {
    display: flex;
    align-items: center;
    background: var(--tabe-bg, #f1f1f1);
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .slide-photo {
    height: 100%;
    border-radius: 10px;
    background-color: gray;
  }

  .slide-text {
    flex: 2;
    padding: 0 10px;
  }

  .slide-button {
    padding: 5px 10px;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  .slide-background {
    width: 100%;
  }

  .slick-slide {
    padding-left: 0;
  }
}

.blockImages {
  display: flex !important;
  justify-content: center !important;
}

.bottomHeader {
  position: absolute;
  align-items: center;
  padding: 10px 10px;
  background-color: var(--box-two);
  z-index: 9999;
  transition: bottom 0.3s ease-in-out;

  @media (max-width: 1199px) {
    display: flex;
    top: -6px;
    left: -8px;
  }

  .burger {
    width: 27px;
    height: 3px;
    background-color: var(--white);
    margin: 6px 0;
    transition: 0.4s;
  }

  .buttons {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
    }
  }
}

.burger.open1 {
  transform: rotate(-45deg) translate(-10px, 5.5px);
}

.burger.open2 {
  opacity: 0;
}

.burger.open3 {
  transform: rotate(45deg) translate(-7px, -2.5px);
}

.wrap {
  margin-top: 15px;
  color: var(--white);
  padding: 20px 0;

  .backgroundContainer {
    background: #1F163F;
    padding-bottom: 20px;
  }

  .contentBlock {
    max-width: 800px;
    margin: 0 auto;

    h1 {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
      text-indent: 5px;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
  }
}

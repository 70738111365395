.overlay {
  width: 100%;
  height: 100vh;
  background: rgba(46, 46, 46, 0.5);
  backdrop-filter: blur(8px);
  z-index: 99999;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  align-items: center;

  .popupContent {
    box-shadow: var(--tableShadow);
    width: 400px;
    min-height: 210px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--form-bg);
    padding: 15px 15px;
    border: 1px solid var(--border-two);
    border-radius: 10px;
    top: -10rem;

    span {
      margin-bottom: 5px;
      margin-top: 5px;
    }

    .head {
      text-align: center;
      margin-bottom: 10px;
      position: relative;

      h2 {
        line-height: 1.2;
        font-size: 18px;
      }

      @media (max-width: 576px) {
        margin-bottom: 19px;
        p, h2 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .iconClose {
      position: absolute;
      right: 1px;
      height: 14px;
      width: 14px;

      path {
        fill: #FFC876;
      }
    }

    .footerTopUp {
      color: #7F8698;
      font-size: 14px;

      span {
        background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 550;
      }
    }
  }

  .enterSumStyle {
    padding: 5px 0;
  }

  .enterSum {
    display: flex;
    justify-content: space-between;

    input {
      width: 70%;
      background: var(--Breadcumnd);
      border: none;
      text-align: right;
      padding-right: 15px;
      font-size: 20px;
    }

    input:focus {
      border: none;
      outline: none;
    }

    button {
      width: 28%;
    }
  }

  .errorStyle {
    color: red;
    padding: 10px 10px 10px 0;
  }

  .loadingStyle {
    background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 550;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
}

.inputBankWrapper {
  position: relative;

  input {
    width: 100%;
    background: var(--Breadcumnd);
    border: none;
    padding-right: 15px;
    font-size: 20px;
    height: 42px;
    padding-left: 55px;
  }

  input:focus {
    border: none;
    outline: none;
  }

  img {
    position: absolute;
    width: 44px;
    left: 5px;
  }
}

.bankSelect {
  font-size: 20px;
  width: 100%;
  background: var(--Breadcumnd);
  height: 42px;
  margin-bottom: 15px;
  color: var(--white);
  padding-left: 55px;
  position: relative;

  &:focus {
    border: none;
    outline: none;
  }

  option {
    top: 0;
  }
}

.bankIconMono {
  top: 8px;
}

.bankBlockIcon {
  position: relative;

  img {
    position: absolute;
    top: 6px;
    width: 30px;
    left: 11px;
  }

  .bankIconABank {
    position: absolute;
    top: 13px;
    width: 45px;
    left: 8px;
  }
}

.successMessage {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 20px;
  }
}

footer {
  padding: 0 12px;

  .footerWrapper {
    background: var(--footer);

    .footerTop {
      padding: 50px 40px 40px;
      border-bottom: 1px solid #5F0FC0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 576px) {
        padding: 50px 20px 40px;
      }

      .footerLinkLeft {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        li a:hover {
          background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        li:not(:last-child) {
          margin-right: 30px;
        }
      }
    }

    .footerBottom {
      padding: 30px 10px 34px;

      p {
        color: var(--white);
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
      }

      p a {
        background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 550;
      }
    }
  }
}
.inputBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  .formLabel {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.4rem;
    padding-bottom: 4px;
    display: inline-block;
  }

  @media screen and (max-width: 400px) {
    margin: 0;
  }

  .error {
    color: red;
    padding-top: 4px;
    visibility: hidden;
    height: 22px;
  }

  .visibleError {
    visibility: visible;
    height: auto;
  }
}


.input {
  position: relative;
  width: 100%;
  border: 1px solid var(--border);
  background: transparent;
  border-radius: 5px;
  background-color: var(--srh);
  font-size: 16px;
  font-weight: 500;
  padding: 14px 15px 15px 40px;
  outline: none;
  color: var(--white);

  @media (max-width: 576px) {
    font-size: 14px;
    padding: 6px 7px 7px 20px;
  }

}

.searchIconInInput {
  position: absolute;
  top: 13px;
  left: 14px;
  width: 18px;
  height: 18px;

  @media (max-width: 576px) {
    top: 8px;
    left: 2px;
    img {
      width: 15px;
    }
  }

}

.iconEye {
  position: absolute;
  right: 15px;
  top: 48px;
  @media (max-width: 576px) {
    top: 39px;
  }
}

.iconsValid {
  position: absolute;
  bottom: 13px;
  left: 11px;
  @media (max-width: 576px) {
    bottom: 4px;
    left: 5px;
    svg {
      width: 12px;
    }
  }
}

.validEmailTrue {
  color: green;
}

.validEmailFalse {
  color: red;
}


.darkTheme {
  background: var(--themeColor) !important;
}

.rows {
  margin-top: 25px;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 3px;
    }

    &:first-child .text {
      width: 56%;
    }

    &:nth-child(2) .text {
      width: 52%;
    }

    &:nth-child(3) .text {
      width: 48%;
    }

    &:nth-child(4) .text {
      width: 44%;
    }
  }

  &.large {
    li .text {
      width: 100%;
    }
  }
}

.wrapSliderStyle {
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;

    @media screen and (max-width: 400px) {
      opacity: 0.5;
    }

  }

  .slick-slide {
    div:first-child {
      display: flex !important;
      align-items: center;
    }
  }

  @media (max-width: 767px) {
    .slick-slider {
      height: 300px;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .slick-slider {
      height: 600px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    .slick-slider {
      height: 450px;
    }
  }

  @media (min-width: 1400px) {
    .slick-slider {
      height: 550px;
    }
  }
}

.blockImg {
  align-self: flex-end;
}

.banner-items .slick-slide {
  background: url(../../../assets/banner/banner-bg.png);
  background-position: top center;
  background-size: cover;
  position: relative;
  height: 450px;

  @media (max-width: 767px) {
    height: 300px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: 600px;
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    height: 450px;
  }

  @media (min-width: 1400px) {
    height: 550px;
  }
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 32px;
}

.slick-dots li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.slick-dots li button {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

.slick-dots li button:hover {
  background-color: #bbb;
}

.slick-dots li.slick-active button {
  width: 30px;
  border-radius: 4px;
  background: linear-gradient(180deg, #FF6E04 -1.75%, #FFC876 100.23%);
}

.slick-dots li:not(.slick-active) button {
  background-color: #ccc;
}

.slick-dots li.slick-active button:before {
  opacity: 0;
  color: black;
}

.banner-items .slick-slide {
  display: flex;
}

.slider-content {
  text-align: left;
  color: white;
  width: 509px;
  padding-left: 60px;
  z-index: 1;
  margin-top: 10px;

  @media screen and (max-width: 576px) {
    padding: 0;
    width: 344px;
    h1 {
      margin: 0;
    }
  }
}

.slider-content-title {
  width: 280px;
  flex-direction: column;
  align-items: flex-start !important;

  h4 {
    margin-top: 0;
  }
}

.slider-buttons {
  margin-top: 20px;
}

.slider-buttons button {
  margin: 0 10px;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.slider-buttons button:hover {
  background-color: #333;
  color: #fff;
}

.slick-prev {
  left: 8px;
  z-index: 1;
}

.slick-next {
  right: 8px;
  z-index: 1;
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 160px;
  background-color: gray;
  overflow: hidden;
  border-radius: 10px;

  &:hover .elementHover {
    bottom: 0;
  }
}

.blockImg {
  position: absolute;
  right: 0;
  bottom: 0;
}



.slider-game-container {
  padding: 0 12px;

  .slider-container {
    position: relative;
    margin: auto;
    padding: 0 15px;
  }

  .slider-title {
    margin: 0;
    padding: 0;
  }

  .slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .slider-buttons button {
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .slide-background {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 245px;
    padding: 18px 18px;
    background-color: gray;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(22, 14, 49, 0) -7.64%, rgba(39, 26, 81, 0.81) 98.03%);
      opacity: 0;
      transition: opacity 0.5s;
    }

    &:hover:before {
      opacity: 1;
    }

    button {
      transform: translateY(calc(100% + 25px));
      transition: transform 0.5s;
    }

    &:hover button {
      transform: translateY(calc(-30px));
    }
  }

  .slide-text {
    flex: 2;
    padding: 0 10px;
  }

  .slide-button {
    padding: 5px 10px;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }

  .slick-slide {
    padding-left: 10px;
  }

  .blockNameStyle {
    width: 170px;
    display: flex;
    justify-content: space-between;
  }
}

.blockImages {
  max-width: 309px;
  margin: auto;
}

.slider-game-container .slick-slide > div {
  margin: 0 5px 30px;
}

:root {
  --base: #FF6E04;
  --title: #3D1879;
  --theme: #03d6f9;
  --theme-one: #fa8fc1;
  --button: #4934d3;
  --box-bg: #3F1276;
  --srh: #2E0F55;
  --section-bg: #101046;
  --section-bg-two: #000036;
  --body: #160E31;
  --chat-bg: #411874;
  --box-three: #523CA4;
  --text-pra: #BBBBBB;
  --bg: #160E31;
  --white: #fff;
  --hover-bg: #4b1093;
  --Breadcumnd: #271A51;
  --icon-bg: #41385A;
  --lightwaight: #76DC00;
  --box-two: #240945;
  --border: #401A6D;
  --form-bg: #342669;
  --footer: #240945;
  --pragraph: #7F8698;
  --prev: #41385A;
  --next: #514477;
  --ratting: #FFAD02;
  --tabe-bg: #2E0F55;
  --video-bg: #342B4E;
  --focus: #EBFF00;
  --gradian-bg: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
  --gradian-text: linear-gradient(180deg, #FF6E04 -1.75%, #FFC876 100.23%);
  --background-clip: text;
  --text-fill-color: transparent;
}
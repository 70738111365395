.overlay {
  width: 100%;
  height: 100vh;
  background: rgba(46, 46, 46, 0.5);
  backdrop-filter: blur(8px);
  z-index: 99999;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  align-items: center;
}

.popupContent {
  box-shadow: var(--tableShadow);
  width: 600px;
  min-height: 210px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--form-bg);
  padding: 30px 30px;
  border: 1px solid var(--border-two);
  border-radius: 10px;

  .head {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    @media (max-width: 576px) {
      margin-bottom: 19px;
      p, h2 {
        padding: 0;
        margin: 0;
        font-size: 18px;
      }
    }
  }

  .iconClose {
    position: absolute;
    right: 1px;
    height: 14px;
    width: 14px;
  }

  .emailBar {
    text-align: center;
    margin-top: 22px;
    @media (max-width: 576px) {
      margin-top: 0;
    }

    .bar {
      display: block;
      width: 100%;
      height: 1px;
      background-color: #C6C6CB;
    }

    .mainText {
      background-color: var(--form-bg);
      transform: translateY(-11px);
      display: inline-block;
      padding: 0 15px;
    }
  }

  .checkBox {
    margin-bottom: 25px;

    .formCheckLabel {
      margin-left: 5px;
    }

    .formCheckInput {
      outline: none;
      border: none;
      background-color: transparent;
      box-shadow: none;
      position: relative;
      background-color: #c1721e;
    }
  }

  @media (max-width: 576px) {
    width: 87%;
    padding: 15px 15px;
  }
}

.items:hover {
  background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.textBase {
  margin-left: 5px;
  background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.classNameWrap {
  margin-bottom: 4px;
  width: 100%;
  @media (max-width: 576px) {
    margin-bottom: 0;
  }

  input {
    background: var(--Breadcumnd);
  }
}

.buttonStyle {
  width: 100%;
}

.popup-content .close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.error {
  color: red;
  padding-top: 4px;
  visibility: hidden;
  height: 22px;
}

.errorMessage {
  color: red;
  font-size: 22px;
  margin-bottom: 25px;
}


.visibleError {
  visibility: visible;
  height: auto;
}

.timeOut {
  margin: 0 auto;
  padding: 50px;
  font-size: 19px;
}
body {
  color: var(--white);
  padding: 0;
  margin: 0;
  background: var(--section-bg);
  overflow-x: auto;
  font-family: "Font Awesome 5 Free", Arial, sans-serif;
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--white);
  font-family: "Inter", sans-serif;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 49px;
  font-weight: 700;
  line-height: 60.8px;
  font-family: "Inter", sans-serif;
}

h2 {
  font-weight: 700;
  font-size: 39px;
  line-height: 60.1px;
  font-family: "Inter", sans-serif;
}

h3 {
  font-size: 31px;
  line-height: 40px;
  font-family: "Inter", sans-serif;
}

h4 {
  font-size: 25px;
  line-height: 31.2px;
  margin-top: -15px;
  font-family: "Inter", sans-serif;
}

h5 {
  font-size: 18px;
  line-height: 24.2px;
  margin-top: -3px;
  font-family: "Inter", sans-serif;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  margin-top: -1px;
  line-height: 19.2px;
  font-family: "Inter", sans-serif;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  margin: 0;
  color: var(--white);
  font-family: "Inter", sans-serif;
}

a:hover {
  text-decoration: none;
  color: var(--white);
}

input:focus {
  color: var(--white);
}

input {
  color: var(--white);
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  color: var(--pragraph-color);
  font-family: "Inter", sans-serif;
}

svg {
  cursor: pointer;
}


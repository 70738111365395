.selectWrap {
  position: relative;
  display: inline-block;
}

.select {
  border: none;
  border-radius: 5px;
  background: var(--Breadcumnd);
  outline: none;
  padding: 5px 30px 5px 32px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  .selectCurrent {
    display: flex;
    align-items: center;
  }

  img {
    width: 15px;
  }

  @media screen and (max-width: 400px) {
    padding: 15px 15px 15px 32px;
  }


  svg {
    path {
      fill: var(--white);
    }
  }
}

.selectIcon {
  margin-right: 8px;
}

.arrowIcon {
  margin-left: auto;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 5px;
  background: var(--Breadcumnd);

  z-index: 1;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: none;

  img {
    width: 15px;
  }
}

.dropdown li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown li:hover {
  background-color: #101046;
}

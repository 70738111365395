.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex: 1;
  @media (max-width: 576px) {
    margin-top: 50px;
  }
}

.text-center {
  text-align: center !important;
}

.bonusParagraph {
  text-indent: 10px;
  @media (max-width: 576px) {
    display: none;
  }
}

@media (min-width: 1000px) {
  .content {
    margin-top: 80px;
  }
}

@media (min-width: 576px) {
  .content {
    margin-top: 80px;
  }
}


.main-content {
  flex: 1;
  overflow: hidden;
  background: var(--body);
}

.left-sidebar,
.right-sidebar {
  width: 240px;
  transition: width 0.5s;
  background-color: var(--box-two);
  position: sticky;
  padding: 35px 15px 0;
  z-index: 99;
  flex-shrink: 0;
}

@media screen and (max-width: 1200px) {
  .left-sidebar,
  .right-sidebar {
    width: 0;
    transition: width 0.5s;
    overflow: hidden;
    padding: 0;
  }
}

* {
  box-sizing: border-box;
}

.container {
  padding: 0 12px;
}

.text-base {
  background: linear-gradient(180deg, #FFC876 -1.75%, #FF6E04 100.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.slider-game-container, .slider-container, .slider-container-group {
  max-width: 1200px;
  margin: 0 auto;
}
.overlay {
  width: 100%;
  height: 100vh;
  background: rgba(46, 46, 46, 0.5);
  backdrop-filter: blur(8px);
  z-index: 99999;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  align-items: center;

  .popupContent {
    box-shadow: var(--tableShadow);
    width: 400px;
    min-height: 210px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--form-bg);
    padding: 15px 15px;
    border: 1px solid var(--border-two);
    border-radius: 10px;
    top: -10rem;
    align-items: center;
    justify-content: space-around;
    font-size: 24px;

    .successTrue {
      color: green;
      text-align: center;
    }

    .successFalse {
      color: red;
    }
  }
}
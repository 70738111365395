.wrap {
  padding: 20px 20px;
  background-color: var(--box-two);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  .headerTopWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 575px) {
      flex-wrap: nowrap;
    }
  }

  .logo {
    padding: 1px;
  }

  .imgSmall {
    margin-left: 45px;
  }

  @media (max-width: 575px) {
    .logo a img {
      height: 35px;
    }
  }

  .middleReferralStyle {
    @media screen and (max-width: 1199px) {
      display: none;
    }

    .middleReferralStyleBlock {
      display: flex;
      align-items: center;

      .homeIconStyle {
        margin-right: 25px;
        padding-right: 22px;
        border-right: 1px solid var(--pragraph);
      }

      .searchStyle {
        display: flex;
        @media (min-width: 768px) {
          display: none;
        }
      }

      .searchInputStyle {
        display: none;
        @media (min-width: 1100px) {
          display: flex;
          margin-left: 25px;
        }

      }
    }
  }

  .searchInputStyleSmall {
    display: none;
    @media (max-width: 576px) {
      display: flex;
    }
  }

  .loinStyle {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;

    }

    @media screen and (max-width: 1199px) {
      .iconMessage {
        display: none;
      }
    }

    .iconMessage {
      margin-right: 20px;
      cursor: pointer;
    }

    .buttonBlockStyle {
      display: flex;
      align-items: center;
      margin-left: 0;

      .borderButton {
        display: inline-block;
      }

      button {
        margin-left: 3%;
      }
    }
  }
}

@media (max-width: 320px) {
  .middleReferralStyle {
    display: none;
  }

  .loinStyle {
    margin-top: 0;

    .iconMessage {
      display: none;
    }

    .buttonBlockStyle {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
      margin-top: 15px;

      button {
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .wrap {
    padding: 10px 8px;
  }
}

.dropdownMenu {
  background: var(--form-bg);;
  position: absolute;
  z-index: 9999;
  top: 100%;
  right: 0;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  width: 290px;

  li {
    color: var(--pragraph);
    display: block;
    width: 100%;
    padding: 5px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
  }

  li:not(:first-child):not(.selectUserTitleText):hover {
    background-color: var(--hover-bg);
    transition: all 0.8s !important;
    color: var(--white);
  }

  .selectUser {
    display: flex;
    align-items: center;
    position: relative;


    svg {
      margin-right: 10px;
    }

    img {
      width: 1.1em;
      margin-right: 10px;
    }

    .userStyle {
      p, h4 {
        color: var(--pragraph);
        margin: 0;
        padding: 0;
      }
    }

    .invoices {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .selectUserAccounts {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;

    svg {
      margin-right: 10px;
    }

    img {
      width: 1.1em;
      margin-right: 10px;
    }

    .userStyle {
      p, h4 {
        color: var(--pragraph);
        margin: 0;
        padding: 0;
      }
    }

    .invoices {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 3px;
    }
  }

  .notInvoices {
    color: red;
  }

  .selectUserUppercase {
    text-transform: uppercase;
  }

  .wallet {
    display: flex;
    align-items: center;

    img {
      width: 2em;
      margin-left: -7px;
    }
  }

  .selectUserTitle {
    font-weight: 800;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    .selectUserTitleText {
      font-weight: 800;
    }
  }
}

.referralStyle {
  background: linear-gradient(180deg, #ffc876 -1.75%, #ff6e04 100.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  align-items: center;
  font-weight: 600;

  .referalStyleIcon {
    margin-right: 5px;
  }
}

.closeIconStyle {
  margin-left: 19px;
  position: absolute;
  right: -5px;
  top: 0;
  padding: 9px 5px 8px 13px;

  svg {
    height: 11px;
    width: 11px;
  }
}